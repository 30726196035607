/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-console */
import { Dispatch, PayloadAction, Store } from '@reduxjs/toolkit';
import moment from 'moment';
import {
    Invitation,
    Inviter,
    InviterOptions,
    Notification,
    Registerer,
    Session,
    SessionState,
    URI,
    UserAgent,
    UserAgentOptions,
    Web
} from 'sip.js';
import { SessionDescriptionHandler } from 'sip.js/lib/platform/web';
import { IncomingRequestMessage, OutgoingInviteRequestDelegate } from 'sip.js/lib/core';
import { Body } from 'sip.js/lib/core/messages/body';
import { CountryCode, parsePhoneNumberFromString } from 'libphonenumber-js';
import { RootState } from '../store';
import {
    appSlice,
    callsSlice,
    getGlobalSettings,
    handleNewVoicemail,
    selectDnd,
    selectHasDevices,
    selectSmartDiallerSelected,
    setVoicemailSeen,
    userSlice
} from '../slices';
import { AssertedIdentity, ICall, IPhoneSettings } from '../../types';
import { isCallSwitch, isPreview, isYay, playCallWaiting, playRingtone, stopRingtone } from '../../helpers';
import { api } from '../../api';

export interface SIPLogin {
    username: string;
    password: string;
    isAus: boolean;
}

interface ISIPPayload {
    callId: string;
    callee?: string;
    isMuted?: string;
    onHold?: string;
    displayName?: string;
}

// eslint-disable-next-line no-nested-ternary

let server: string;
let alteredUri: string;
let userAgentString: string;

switch (true) {
    case isYay:
        userAgentString = 'Yay.com 5.0';
        break;

    case isCallSwitch:
        server = 'wss://talk.voipcp.com/';
        alteredUri = 'talk.voipcp.com';
        userAgentString = 'CallSwitch One 5.0';
        break;

    default:
        server = 'wss://talk.voipcp.com/';
        alteredUri = 'talk.voipcp.com';
        userAgentString = 'PulseHd 5.0';
}

export const serverArr = [
    { server: 'wss://talk.yay.com/', uri: 'talk.yay.com' },
    { server: 'wss://au.talk.yay.com/', uri: 'au.talk.yay.com' }
];

let userAgent: UserAgent | undefined;
let registerer: Registerer | undefined;
let calls: (Inviter | Invitation)[] = [];
// TODO: Clean up bodyDictionary/callStatusDictionary on call terminated
const bodyDictionary: { [key: string]: Body } = {};
const callsOnHold = {};
let callStatusDictionary: { [key: string]: number } = {};

let dnd = false;
let callWaitingBeepOff = false;
let allowInternalCalls = false;
let bringAppToFrontOff = false;
let collectCallFeedback = false;
let popCrm: 'never' | 'new' | 'same' = 'never';
let popWhenAnswered = false;
let ringtoneVolumeValue = 100;

// Used to guard against overlapping reconnection attempts
let attemptingReconnection = false;

// Function which recursively attempts reconnection
const attemptReconnection = (): void => {
    const reconnectionDelay = 4;

    // Reconnection attempt already in progress
    if (attemptingReconnection) {
        return;
    }

    // We're attempting a reconnection
    attemptingReconnection = true;

    setTimeout(() => {
        // If userAgent isn't defined
        if (!userAgent) {
            attemptingReconnection = false;
            attemptReconnection();
            return;
        }

        // Attempt reconnect
        userAgent
            .reconnect()
            .then(async () => {
                // Reconnect attempt succeeded
                attemptingReconnection = false;
                if (!registerer) {
                    registerer = new Registerer(userAgent as UserAgent, {
                        expires: 80,
                        // this is 75% of 80s -> 60s
                        refreshFrequency: 75
                    });
                }
                await registerer.register().catch(() => {});
            })
            .catch(() => {
                // Reconnect attempt failed
                attemptingReconnection = false;
                attemptReconnection();
            });
    }, reconnectionDelay * 1500);
};

// Monitor network connectivity and attempt reconnection when browser goes online
window.addEventListener('online', () => {
    attemptReconnection();
});

const integrationChecker = async (
    integrationUuid: string,
    userUri: string | undefined,
    crmDir: typeof popCrm,
    countryCode: CountryCode
) => {
    if (!userUri) return;

    const toNumber = parsePhoneNumberFromString(userUri, countryCode);

    if (!toNumber || !toNumber.nationalNumber) return;

    api.matchIntegrationNumber({
        uriUser: `0${toNumber?.nationalNumber}`,
        integrationUuid
    })
        .then(response => {
            if (response.status === 204) return;
            if (response.data.result.length > 0) {
                if (crmDir === 'new') {
                    window.open(response.data.result[0]);
                } else {
                    window.open(response.data.result[0], 'crm_pop');
                }
                // TODO - We should save this window and re-use it if possible
            }
        })
        .catch(() => {
            // We can ignore these for now
        });
};

const SipHeader = {
    InternalCall: 'X-Internal-Call',
    RoomId: 'X-Room-Id',
    RoomAuthToken: 'X-Room-Auth-Token',
    CdrUuid: 'X-Cdr-Uuid',
    Ctd: 'X-Click-To-Dial',
    AssertedIdentity: 'P-Asserted-Identity'
};

let lock = false;

const connectSip = async ({ username, password, isAus }: SIPLogin, store: Store) => {
    if (lock) {
        return;
    }
    lock = true;

    if (isYay) {
        // await testYaySipServers(username, password);
        if (isAus) {
            server = serverArr[1].server;
            alteredUri = serverArr[1].uri;
        } else {
            server = serverArr[0].server;
            alteredUri = serverArr[0].uri;
        }
    }

    if (isPreview) {
        const localServeStr: string | null = localStorage.getItem('local_serve');
        let localServe: { server: string; uri: string; count: number; date: number } | undefined;

        if (localServeStr) {
            try {
                localServe = JSON.parse(localServeStr);

                if (localServe?.server && localServe?.uri) {
                    server = localServe.server;
                    alteredUri = localServe.uri;
                }
            } catch {
                console.error('Failed to parse sip proxy server');
            }
        }
    }

    const userAgentOptions: UserAgentOptions = {
        authorizationUsername: username,
        authorizationPassword: password,
        transportOptions: { server },
        userAgentString,
        uri: UserAgent.makeURI(`sip:${username}@${alteredUri}`),
        sessionDescriptionHandlerFactoryOptions: {
            peerConnectionConfiguration: {
                bundlePolicy: 'max-compat',
                iceServers: []
            }
        },
        allowLegacyNotifications: true,
        noAnswerTimeout: 9999,
        logLevel: 'warn',
        delegate: {
            onInvite: invitation => {
                const settings: IPhoneSettings = (store.getState() as RootState).user.settings
                    ?.phone.settings;
                const callsRedux: ICall[] = (store.getState() as RootState).sip.calls;
                const sipRedux = (store.getState() as RootState).sip;
                const { extension, country_code } = (store.getState() as RootState).user;
                dnd = selectDnd(store.getState()).dnd;

                if (settings?.popCrm) {
                    popCrm = settings.popCrm;
                }

                if (settings?.popWhenAnswered) {
                    popWhenAnswered = settings.popWhenAnswered;
                }

                if (sipRedux.callFeedback.enabled) {
                    collectCallFeedback = sipRedux.callFeedback.enabled;
                }

                if (settings?.allowInternalCalls) {
                    allowInternalCalls = settings.allowInternalCalls;
                }

                if (settings?.callWaitingBeepOff) {
                    callWaitingBeepOff = settings.callWaitingBeepOff;
                }

                if (settings?.ringtoneVolumeValue) {
                    ringtoneVolumeValue = settings.ringtoneVolumeValue;
                }

                bringAppToFrontOff = settings.bringAppToFrontOff || false;

                const crmIntegrationUuids = settings?.crmIntegrationUuids || [];

                if (
                    (dnd && !allowInternalCalls) ||
                    (dnd &&
                        allowInternalCalls &&
                        invitation.request.getHeader(SipHeader.InternalCall) !== 'true')
                ) {
                    invitation.reject({
                        statusCode: 480,
                        reasonPhrase: 'User On Do Not disturb'
                    });
                } else {
                    const roomId = invitation.request.getHeader(SipHeader.RoomId);
                    const auth_token = invitation.request.getHeader(SipHeader.RoomAuthToken);
                    const cdr = invitation.request.getHeader(SipHeader.CdrUuid);
                    const fromExtension = invitation.request.parseHeader('From').uri.raw.user;

                    const initGroup = callsRedux.find(
                        c => c.id === 'group_init' && c.roomId === roomId
                    );

                    if (roomId && fromExtension === String(extension) && !initGroup) {
                        invitation.reject();
                        return;
                    }

                    if (
                        calls.some(call =>
                            [
                                SessionState.Initial,
                                SessionState.Establishing,
                                SessionState.Established
                            ].includes(call.state)
                        ) ||
                        callsRedux.some(
                            call =>
                                call.state &&
                                [
                                    SessionState.Initial,
                                    SessionState.Establishing,
                                    SessionState.Established
                                ].includes(call.state)
                        )
                    ) {
                        if (!callWaitingBeepOff) playCallWaiting();
                    } else if (!initGroup)
                        playRingtone({ callId: invitation.id, volume: ringtoneVolumeValue });

                    if (crmIntegrationUuids && popCrm !== 'never' && !popWhenAnswered) {
                        crmIntegrationUuids.forEach(integrationUuid => {
                            integrationChecker(
                                integrationUuid,
                                invitation.request.from.uri.user,
                                popCrm,
                                country_code
                            );
                        });
                    }

                    if (!bringAppToFrontOff && (window as any).electron) {
                        (window as any).electron.send('bring-window-to-front');
                        window.focus();
                    }

                    if ((window as any).electron) {
                        if (invitation.state === SessionState.Initial) {
                            (window as any).electron.send('set-touch-bar', {
                                type: 'initial-call',
                                payload: {
                                    id: invitation.id,
                                    number: invitation.remoteIdentity?.uri?.user
                                }
                            });
                        } else {
                            (window as any).electron.send('set-touch-bar', { type: 'clear' });
                        }
                    }

                    calls = [...calls, invitation];

                    const newCallObj: ICall = {
                        id: invitation.id,
                        displayName: invitation.remoteIdentity?.displayName,
                        callee: invitation.remoteIdentity?.uri?.user,
                        state: invitation.state,
                        callSipId: invitation.request.callId,
                        roomId,
                        auth_token,
                        cdr
                    };

                    // if (replyStr) {
                    //     newCallObj.callSipId = replyStr;
                    // }

                    // if (roomId) {
                    //     newCallObj.roomId = roomId;
                    //     newCallObj.auth_token = auth_token;
                    //     newCallObj.state = SessionState.Initial;
                    //
                    //     store.dispatch(callsSlice.actions.newCall(newCallObj));
                    //     return;
                    // }

                    store.dispatch(callsSlice.actions.newCall(newCallObj));

                    if (
                        (store.getState() as RootState).user.settings.phone.settings
                            ?.autoAnswerClickToDial &&
                        invitation.request.getHeader(SipHeader.Ctd) === 'true' &&
                        calls.every(
                            call =>
                                ![SessionState.Establishing, SessionState.Established].includes(
                                    call.state
                                )
                        )
                    ) {
                        store.dispatch({
                            type: 'sip/answer',
                            payload: invitation.id
                        });
                    }

                    // Handler for when a received attended transfer is confirmed
                    handleCsecTwo(invitation, store);

                    invitation.stateChange.addListener((state: SessionState) => {
                        switch (state) {
                            case SessionState.Established:
                                if (roomId) {
                                    break;
                                }
                                console.log(`sip/incoming-invitation state changed to ${state}`);

                                if (crmIntegrationUuids && popCrm !== 'never' && popWhenAnswered) {
                                    crmIntegrationUuids.forEach(integrationUuid => {
                                        integrationChecker(
                                            integrationUuid,
                                            invitation.request.from.uri.user,
                                            popCrm,
                                            country_code
                                        );
                                    });
                                }

                                // this sets the active call id, so it can be reference when providing call feedback
                                if (collectCallFeedback && invitation.cdrUuid) {
                                    const cdrUuid = invitation.cdrUuid?.trim();

                                    const isInternal =
                                        invitation.remoteIdentity?.uri?.user &&
                                        invitation.remoteIdentity?.uri?.user.length <= 5;

                                    if (cdrUuid && !isInternal) {
                                        store.dispatch(
                                            callsSlice.actions.addSingleFeedbackForm({
                                                invite_uuid: invitation.id,
                                                call_uuid: cdrUuid,
                                                note: undefined,
                                                mood: undefined,
                                                active_tags: []
                                            })
                                        );
                                    }
                                }
                                break;

                            case SessionState.Terminating:
                            case SessionState.Terminated:
                                const inviteCall = (store.getState() as RootState).sip.calls.find(
                                    call => call.id === invitation.id
                                );

                                if (!inviteCall || !inviteCall?.answered) {
                                    console.log(
                                        `sip/incoming-invitation state changed to ${state}`
                                    );

                                    store.dispatch(
                                        callsSlice.actions.callTerminating({
                                            id: invitation.id
                                        })
                                    );

                                    setTimeout(() => {
                                        store.dispatch(
                                            callsSlice.actions.callTerminated({
                                                id: invitation.id
                                            })
                                        );
                                    }, 1500);
                                }

                                (window as any).electron?.send('set-touch-bar', { type: 'clear' });
                                break;

                            default:
                                break;
                        }
                    });
                }
            },
            onDisconnect: (error?: Error) => {
                // Only attempt to reconnect if network/server dropped the connection (if there is an error)
                if (error) {
                    attemptReconnection();
                }
            },
            onNotify(notification: Notification) {
                const event = notification.request.getHeader('Event');
                const notificationBody = notification.request.body;

                switch (event) {
                    case 'VOICEMAIL-NEW':
                        try {
                            const [mailbox, voicemail] = notificationBody.split(':');

                            if (
                                !mailbox ||
                                !voicemail ||
                                mailbox.length !== 36 ||
                                voicemail.length !== 36
                            ) {
                                break;
                            }

                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            store.dispatch(handleNewVoicemail(mailbox, voicemail));
                        } catch (e) {}
                        break;
                    case 'VOICEMAIL-SEEN':
                        try {
                            const [mailbox, voicemail] = notificationBody.split(':');

                            if (
                                !mailbox ||
                                !voicemail ||
                                mailbox.length !== 36 ||
                                voicemail.length !== 36
                            ) {
                                break;
                            }

                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            store.dispatch(setVoicemailSeen(mailbox, voicemail));
                        } catch (e) {}
                        break;
                    case 'GLOBAL-SETTING':
                        if (notificationBody === 'UPDATED') {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            store.dispatch(getGlobalSettings(notificationBody));
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    };

    userAgent = new UserAgent(userAgentOptions);
    registerer = new Registerer(userAgent, {
        expires: 80,
        // this is 75% of 80s -> 60s
        refreshFrequency: 75
    });

    try {
        await userAgent.start();
    } catch (error) {
        console.warn(error);
    }

    try {
        await registerer.register();
    } catch (error) {
        console.warn(error);
    }
};

function handleCsecTwo(initiator: Inviter | Invitation, store: Store) {
    if (!initiator.delegate) {
        initiator.delegate = {};
    }

    initiator.delegate.onInvite = (innerReq: IncomingRequestMessage) => {
        const assertionIdentity = innerReq.getHeader(SipHeader.AssertedIdentity);

        if (assertionIdentity) {
            const assertedIdentity = parseAssertedIdentity(assertionIdentity);

            store.dispatch(
                callsSlice.actions.updateCall({
                    id: initiator.id,
                    assertedIdentity
                })
            );
        }

        const assertionRoomID = innerReq.getHeader(SipHeader.RoomId);
        const assertionRoomAuth = innerReq.getHeader(SipHeader.RoomAuthToken);

        if (assertionRoomID && assertionRoomAuth) {
            store.dispatch(
                callsSlice.actions.updateCall({
                    id: initiator.id,
                    roomId: assertionRoomID,
                    auth_token: assertionRoomAuth
                })
            );
        }
    };
}

// TODO - Check if this is even needed if using go aus check
// const testYaySipServers = async (username: string, password: string) => {
//     const localServeStr: string | null = localStorage.getItem('local_serve');
//     let localServe: { server: string; uri: string; count: number; date: number } | undefined;
//
//     if (localServeStr) {
//         try {
//             localServe = JSON.parse(localServeStr);
//         } catch {}
//     }
//
//     // Reset localServe if number of servers have changed or if last
//     // get was more than 12 hours ago
//     if (
//         localServe &&
//         localServe.count === serverArr.length &&
//         localServe.date > moment().subtract(12, 'hours').unix()
//     ) {
//         server = localServe.server;
//         alteredUri = localServe.uri;
//         return;
//     }
//
//     const promises = serverArr.map(item =>
//         testUserAgent(item.server, item.uri, username, password)
//     );
//
//     let cur: number | undefined;
//     let found: { server: string; uri: string } | undefined;
//
//     await Promise.allSettled(promises).then((r: any[]) => {
//         for (let i = 0; i < r.length; i += 1) {
//             if (r[i].value !== Infinity && (!cur || cur > r[i].value)) {
//                 cur = r[i].value;
//                 found = serverArr[i];
//             }
//         }
//     });
//
//     if (found) {
//         localStorage.setItem(
//             'local_serve',
//             JSON.stringify({
//                 ...found,
//                 count: serverArr.length,
//                 date: moment().unix()
//             })
//         );
//
//         server = found.server;
//         alteredUri = found.uri;
//     }
// };
//
// const testUserAgent = (
//     serverToCheck: string,
//     uriToCheck: string,
//     username: string,
//     password: string
// ) =>
//     new Promise<number>(resolve => {
//         const userAgentOptions: UserAgentOptions = {
//             authorizationUsername: username,
//             authorizationPassword: password,
//             transportOptions: { server: serverToCheck },
//             userAgentString,
//             uri: UserAgent.makeURI(`sip:${username}@${uriToCheck}`),
//             sessionDescriptionHandlerFactoryOptions: {
//                 peerConnectionConfiguration: {
//                     bundlePolicy: 'max-compat',
//                     iceServers: []
//                 }
//             },
//             logLevel: 'warn'
//         };
//
//         const tempUserAgent = new UserAgent(userAgentOptions);
//
//         try {
//             tempUserAgent.start().then(() => {
//                 const core = tempUserAgent.userAgentCore;
//
//                 const requestURI = UserAgent.makeURI(`sip:${username}@${uriToCheck}`);
//                 if (requestURI) {
//                     const message = core.makeOutgoingRequestMessage(
//                         'OPTIONS',
//                         requestURI,
//                         requestURI.clone(),
//                         requestURI.clone(),
//                         {}
//                     );
//                     const start = performance.now();
//                     core.request(message, {
//                         onAccept: () => {
//                             const end = performance.now();
//                             tempUserAgent.stop().then(() => {
//                                 resolve(end - start);
//                             });
//                         },
//                         onReject: () => {
//                             tempUserAgent.stop().then(() => {
//                                 resolve(Infinity);
//                             });
//                         }
//                     });
//                 }
//             });
//         } catch (error) {
//             console.warn(error);
//             resolve(Infinity);
//         }
//     });

export const getPeerConnection = (callId: string): RTCPeerConnection | undefined => {
    const found = calls.find(c => c.id === callId)
        ?.sessionDescriptionHandler as Web.SessionDescriptionHandler;

    if (!found || !found.peerConnection) return undefined;

    return found.peerConnection;
};

const listenForMediaTracks = (store: Store, session: Session) => {
    const { peerConnection } = session.sessionDescriptionHandler as Web.SessionDescriptionHandler;

    if (peerConnection) {
        peerConnection.ontrack = event => {
            switch (event.track.kind) {
                case 'audio':
                    store.dispatch(
                        callsSlice.actions.updateCall({
                            id: session.id,
                            audioTrack: event.track
                        })
                    );
                    break;

                case 'video':
                    store.dispatch(
                        callsSlice.actions.updateCall({
                            id: session.id,
                            videoTracks: [event.track]
                        })
                    );
                    break;
                default:
                    break;
            }
        };
    }
};

const setupRemoteMedia = (store: Store, session: Session) => {
    const { peerConnection } = session.sessionDescriptionHandler as Web.SessionDescriptionHandler;

    if (peerConnection) {
        peerConnection.getReceivers().forEach((receiver: { track: MediaStreamTrack }) => {
            switch (receiver.track.kind) {
                case 'audio':
                    store.dispatch(
                        callsSlice.actions.updateCall({
                            id: session.id,
                            audioTrack: receiver.track
                        })
                    );
                    break;

                case 'video':
                    store.dispatch(
                        callsSlice.actions.updateCall({
                            id: session.id,
                            videoTracks: [receiver.track]
                        })
                    );
                    break;
                default:
                    break;
            }
        });
    }
};

// const setupLocalMedia = (store: Store, session: Invitation | Inviter) => {
// (session.sessionDescriptionHandler as Web.SessionDescriptionHandler).peerConnection
//     .getSenders()
//     .forEach((receiver: { track: MediaStreamTrack }) => {
//         switch (receiver.track.kind) {
//             case 'video':
//                 store.dispatch(
//                     callsSlice.actions.updateCall({
//                         id: session.id,
//                         videoTracks: [receiver.track]
//                     })
//                 );
//                 break;
//             default:
//                 break;
//         }
//     });
// };

const enableSenderTracks = (invitation: Session, muted: boolean) => {
    const { sessionDescriptionHandler } = invitation;

    if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
        throw new Error(
            "Session's session description handler not instance of SessionDescriptionHandler."
        );
    }

    const { peerConnection } = sessionDescriptionHandler;
    if (!peerConnection) return;

    peerConnection.getSenders().forEach(sender => {
        if (sender.track) {
            sender.track.enabled = !muted;
        }
    });
};

const sendDTMF = (invitation: Session, dtmf: string): void => {
    const { sessionDescriptionHandler } = invitation;

    if (!(sessionDescriptionHandler instanceof SessionDescriptionHandler)) {
        throw new Error(
            "Session's session description handler not instance of SessionDescriptionHandler."
        );
    }

    const { peerConnection } = sessionDescriptionHandler;
    if (!peerConnection) return;

    peerConnection.getSenders().forEach(sender => {
        if (sender.dtmf && sender.dtmf.canInsertDTMF) {
            sender.dtmf.insertDTMF(dtmf);
        }
    });
};

const toggleHold = (store: Store, invitation: Session, onHold: boolean) => {
    const delegate: OutgoingInviteRequestDelegate = {
        onAccept: (response): void => {
            response.ack();
            store.dispatch(
                callsSlice.actions.updateCall({
                    id: invitation.id,
                    onHold
                })
            );
            // 3) once hold is processed set to false
            callsOnHold[invitation.id] = false;
        }
    };

    // 1) if hold button clicked and not processed return
    if (callsOnHold[invitation.id]) return;

    // 2) on start set hold button to true
    callsOnHold[invitation.id] = true;

    let body = bodyDictionary[invitation.id];
    if (onHold && body?.content) {
        if (!/a=(sendrecv|sendonly|recvonly|inactive)/.test(body.content)) {
            body = {
                ...body,
                content: body.content.replace(/(m=[^\r]*\r\n)/g, '$1a=sendonly\r\n')
            };
        } else {
            body = {
                ...body,
                content: body.content.replace(/a=sendrecv\r\n/g, 'a=sendonly\r\n')
            };
            body = {
                ...body,
                content: body.content.replace(/a=recvonly\r\n/g, 'a=inactive\r\n')
            };
        }
    }

    // Send re-INVITE
    invitation?.dialog?.invite(delegate, { body });
};

const createTargetURI = (callee: string, countryCode: CountryCode): URI | undefined => {
    const parsedNumber = parsePhoneNumberFromString(callee, countryCode);
    let target = callee;

    if (parsedNumber?.isValid()) {
        target = parsedNumber.formatInternational();
    }

    if (/#/.test(callee)) {
        target = callee.replace(/#/g, '%23');
    }

    return UserAgent.makeURI(`sip:${target.replace(/\s/g, '')}@${alteredUri}`);
};

const PAINumberRegex = /<sip:(.*?)@/gm;
const PAINameRegex = /^"(.*?)"/gm;

const parseAssertedIdentity = (inp: string): AssertedIdentity => {
    const assertedIdentity: AssertedIdentity = {};

    const nameParse = inp.match(PAINameRegex);
    if (nameParse && nameParse[1] !== 'DDI') {
        // eslint-disable-next-line prefer-destructuring
        assertedIdentity.name = nameParse[0].replaceAll('"', '');
    }

    const numberParse = inp.match(PAINumberRegex);
    if (numberParse) {
        // eslint-disable-next-line prefer-destructuring
        assertedIdentity.number = numberParse[0].split(':')?.[1]?.replace('@', '');
    }

    return assertedIdentity;
};
export const sip =
    (store: Store) => (next: Dispatch) => (action: PayloadAction<SIPLogin | ISIPPayload | any>) => {
        const { payload, type } = action;
        const state = store.getState() as RootState;

        const settings: IPhoneSettings = (store.getState() as RootState).user?.settings?.phone
            .settings;

        if (settings?.callWaitingBeepOff) {
            callWaitingBeepOff = settings.callWaitingBeepOff;
        }

        const smartDiallerSelected = selectSmartDiallerSelected(state);
        const inviteWithoutSdp = !selectHasDevices(state);

        const crmIntegrationUuids = settings?.crmIntegrationUuids || [];

        const inviterOptions: InviterOptions = inviteWithoutSdp
            ? {
                  anonymous: state.user?.caller_id === '0' || false,
                  inviteWithoutSdp
              }
            : {
                  anonymous: state.user?.caller_id === '0' || false,
                  sessionDescriptionHandlerOptions: {
                      constraints: {
                          audio: {
                              deviceId:
                                  state.user?.settings.phone.settings?.inputDeviceId || 'default'
                          },
                          video: false
                      }
                  },
                  earlyMedia: true
              };

        switch (type) {
            case 'sip/connect':
                connectSip(payload as SIPLogin, store).then();
                break;

            case 'sip/call':
                if (
                    state.sip?.calls.some(
                        call =>
                            call.state &&
                            call.callee === payload.callee &&
                            [SessionState.Initial, SessionState.Establishing].includes(call.state)
                    )
                ) {
                    break;
                }
                const target = createTargetURI(payload.callee, state.user?.country_code);

                if (!target || !userAgent) break;
                const inviter = new Inviter(userAgent, target, inviterOptions);

                if (smartDiallerSelected) {
                    const smartDiallerTarget = `${smartDiallerSelected.type}:${smartDiallerSelected.data}`;

                    inviter.request.setHeader('X-Transfer-Target', smartDiallerTarget);
                }

                if (
                    crmIntegrationUuids &&
                    settings?.popCrm &&
                    settings.popCrm !== 'never' &&
                    settings.popOnOutbound
                ) {
                    crmIntegrationUuids.forEach(integrationUuid => {
                        integrationChecker(
                            integrationUuid,
                            inviter.request.to.uri.user,
                            settings.popCrm,
                            state.user.country_code
                        );
                    });
                }

                calls.forEach(call => {
                    if (call.state === SessionState.Established && call.id !== inviter.id) {
                        toggleHold(store, call, true);
                    }
                });
                state.sip.calls
                    .filter(c => c.roomId)
                    .forEach(c => {
                        store.dispatch(
                            callsSlice.actions.updateCall({
                                ...c,
                                onHold: true
                            })
                        );
                    });

                calls = [...calls, inviter];
                const callObject: ICall = {
                    id: inviter.id,
                    displayName: payload.displayName || payload.callee,
                    callee: payload.callee,
                    state: inviter.state,
                    isOutbound: true,
                    beingTransferred: false,
                    callSipId: inviter.request.callId,
                    smartDiallerData: smartDiallerSelected
                        ? {
                              type: smartDiallerSelected.type,
                              title: smartDiallerSelected.label,
                              data: smartDiallerSelected.data
                          }
                        : undefined,
                    smartDialOnly: inviteWithoutSdp,
                    isMuted: inviteWithoutSdp,
                    interTenant: payload.tenant
                };

                if (payload.beingTransferredTo) {
                    callObject.beingTransferredTo = payload.beingTransferredTo;
                }

                store.dispatch(callsSlice.actions.newCall(callObject));
                store.dispatch(
                    callsSlice.actions.setLastCalledNumber({
                        callee: payload.callee,
                        display_name: payload.display_name
                    })
                );

                handleCsecTwo(inviter, store);

                inviter.stateChange.addListener((sessionState: SessionState) => {
                    console.log(`sip/call Session state changedsss to ${sessionState}`);

                    const isInternal =
                        inviter.remoteIdentity?.uri?.user &&
                        inviter.remoteIdentity?.uri?.user.length <= 5;

                    switch (sessionState) {
                        case SessionState.Initial:
                            break;

                        case SessionState.Establishing:
                            if (!inviteWithoutSdp) {
                                listenForMediaTracks(store, inviter);
                            }
                            break;

                        case SessionState.Established:
                            bodyDictionary[inviter.id] = {
                                ...(inviter.dialog?.offer as Body)
                            };

                            store.dispatch(
                                callsSlice.actions.updateCall({
                                    id: inviter.id,
                                    state: SessionState.Established,
                                    answered: true,
                                    answeredTime: moment().valueOf()
                                })
                            );

                            (window as any).electron?.send('set-touch-bar', {
                                type: 'established-call',
                                payload: {
                                    id: inviter.id,
                                    number: inviter.remoteIdentity?.uri?.user
                                }
                            });
                            break;

                        case SessionState.Terminating:
                        case SessionState.Terminated:
                            (window as any).electron?.send('set-touch-bar', { type: 'clear' });

                            if (state.sip.callFeedback.enabled && inviter.cdrUuid && !isInternal) {
                                // enable form because the call was established
                                store.dispatch(
                                    callsSlice.actions.addSingleFeedbackForm({
                                        invite_uuid: inviter.id,
                                        call_uuid: inviter.cdrUuid,
                                        note: undefined,
                                        mood: undefined,
                                        active_tags: []
                                    })
                                );
                            }
                            store.dispatch(
                                callsSlice.actions.updateCall({
                                    id: inviter.id,
                                    terminatedTime: moment().valueOf()
                                })
                            );

                            store.dispatch(
                                callsSlice.actions.callTerminating({
                                    id: inviter.id
                                })
                            );

                            setTimeout(() => {
                                store.dispatch(
                                    callsSlice.actions.callTerminated({
                                        id: inviter.id
                                    })
                                );
                                calls = calls.filter(call => call.id !== inviter.id);
                            }, 1500);
                            break;

                        default:
                            break;
                    }
                });

                inviter
                    .invite({
                        withoutSdp: inviteWithoutSdp
                    })
                    .then(request => {
                        let isRinging = false;

                        if (request.delegate) {
                            request.delegate.onProgress = response => {
                                const { callId, statusCode } = response.message;

                                if (
                                    statusCode === 180 &&
                                    callStatusDictionary[callId] !== 183 &&
                                    !isRinging
                                ) {
                                    isRinging = true;
                                    playRingtone({
                                        callId: inviter.id,
                                        isOutbound: true,
                                        volume: state.user.settings?.phone.settings?.callVolumeValue
                                    });
                                }

                                if (statusCode === 183) {
                                    const { peerConnection } =
                                        inviter.sessionDescriptionHandler as Web.SessionDescriptionHandler;

                                    if (
                                        peerConnection &&
                                        peerConnection.signalingState !== 'stable'
                                    ) {
                                        const description = new RTCSessionDescription({
                                            type: 'answer', // pranswer
                                            sdp: response.message.body
                                        });

                                        // TODO: Check that peer connection is stable and not set by library or don't set remove desc here.

                                        try {
                                            peerConnection.setRemoteDescription(description).then();
                                        } catch (error) {
                                            console.log('Setting remote description error', error);
                                        }

                                        isRinging = false;
                                        stopRingtone(callId);
                                    }
                                }

                                if (statusCode) {
                                    callStatusDictionary = {
                                        ...callStatusDictionary,
                                        [callId]: statusCode
                                    };
                                }
                            };

                            if (smartDiallerSelected) {
                                request.delegate.onReject = res => {
                                    store.dispatch({
                                        type: 'sip/end-call',
                                        payload: inviter.id
                                    });

                                    if (res.message.statusCode && res.message.statusCode !== 487) {
                                        store.dispatch(
                                            appSlice.actions.setSmartDiallerError({
                                                reasonPhrase: res.message.reasonPhrase,
                                                statusCode: res.message.statusCode,
                                                ...callObject
                                            })
                                        );
                                    }
                                };
                            }
                        }
                    })
                    .catch(() => {});
                break;

            case 'sip/answer':
                const answerInvitation = calls.find(inv => inv.id === payload) as Invitation;
                if (
                    !answerInvitation ||
                    [SessionState.Establishing, SessionState.Established].includes(
                        answerInvitation.state
                    )
                ) {
                    break;
                }

                answerInvitation.stateChange.addListener((sessionState: SessionState) => {
                    console.log(`sip/answer Session state changed to ${sessionState}`);

                    calls.forEach(call => {
                        if (
                            call.state === SessionState.Established &&
                            answerInvitation.state !== SessionState.Terminated &&
                            call.id !== answerInvitation.id
                        ) {
                            toggleHold(store, call, true);
                        }
                    });
                    state.sip.calls
                        .filter(c => c.roomId)
                        .forEach(c => {
                            store.dispatch(
                                callsSlice.actions.updateCall({
                                    ...c,
                                    onHold: true
                                })
                            );
                        });

                    switch (sessionState) {
                        case SessionState.Initial:
                        case SessionState.Establishing:
                            break;

                        case SessionState.Established:
                            setupRemoteMedia(store, answerInvitation);

                            bodyDictionary[answerInvitation.id] = {
                                ...(answerInvitation.dialog?.answer as Body)
                            };

                            store.dispatch(
                                callsSlice.actions.updateCall({
                                    id: answerInvitation.id,
                                    state: answerInvitation.state,
                                    answered: true,
                                    answeredTime: moment().valueOf()
                                })
                            );

                            calls.forEach(
                                c => c.state === SessionState.Initial && stopRingtone(c.id)
                            );

                            (window as any).electron?.send('set-touch-bar', {
                                type: 'established-call',
                                payload: {
                                    id: answerInvitation.id,
                                    number: answerInvitation.remoteIdentity?.uri?.user
                                }
                            });
                            break;

                        case SessionState.Terminating:
                        case SessionState.Terminated:
                            (window as any).electron?.send('set-touch-bar', { type: 'clear' });

                            store.dispatch(
                                callsSlice.actions.updateCall({
                                    id: answerInvitation.id,
                                    terminatedTime: moment().valueOf()
                                })
                            );

                            store.dispatch(
                                callsSlice.actions.callTerminating({
                                    id: answerInvitation.id
                                })
                            );

                            setTimeout(() => {
                                store.dispatch(
                                    callsSlice.actions.callTerminated({
                                        id: answerInvitation.id
                                    })
                                );
                            }, 1500);
                            break;

                        default:
                            break;
                    }
                });

                answerInvitation
                    .accept({
                        sessionDescriptionHandlerOptions: {
                            constraints: {
                                audio: {
                                    deviceId:
                                        state.user?.settings.phone.settings?.inputDeviceId ||
                                        'default'
                                },
                                video: false
                            }
                        }
                    })
                    .then(() => {})
                    .catch(() => {});
                break;

            case 'sip/attended-transfer':
                const originalCall = calls.find(call => call.id === payload.originalCallId);
                const newCallToBeTransferred = calls.find(
                    call => call.id === payload.currentCallId
                );

                if (
                    originalCall &&
                    newCallToBeTransferred &&
                    ![originalCall.state, newCallToBeTransferred.state].includes(
                        SessionState.Terminated
                    )
                ) {
                    try {
                        originalCall.refer(newCallToBeTransferred).then();
                    } catch (error) {}
                }
                break;

            case 'sip/blind-transfer':
                const blindTransferSession = calls.find(call => call.id === payload.callId);
                if (!blindTransferSession) break;

                const transferURI = createTargetURI(payload.secondCallee, state.user?.country_code);

                if (transferURI) {
                    blindTransferSession
                        .refer(transferURI, {
                            // Example of extra headers in REFER request
                            requestOptions: {
                                extraHeaders: [`X-Referred-By-Someone: ${payload.callee}`]
                            },
                            requestDelegate: {
                                onAccept: () => {}
                            },
                            onNotify: notification => {
                                notification.accept().then();
                            }
                        })
                        .then();
                }
                break;

            case 'sip/end-call':
                const endCallSession = calls.find(inv => inv.id === payload);
                if (!endCallSession) break;

                switch (endCallSession.state) {
                    case SessionState.Initial:
                    case SessionState.Establishing:
                        if (endCallSession instanceof Inviter) {
                            // An unestablished outgoing session
                            (endCallSession as Inviter)
                                .cancel()
                                .then(() => {
                                    store.dispatch(
                                        callsSlice.actions.callTerminated({
                                            id: endCallSession.id
                                        })
                                    );
                                })
                                .catch(err => console.warn(err));
                        } else {
                            // An unestablished incoming session
                            endCallSession
                                .reject()
                                .then(() => {
                                    store.dispatch(
                                        callsSlice.actions.callTerminated({
                                            id: endCallSession.id
                                        })
                                    );
                                })
                                .catch(err => console.warn(err));
                        }
                        break;

                    case SessionState.Established:
                        store.dispatch(
                            callsSlice.actions.callTerminating({
                                id: endCallSession.id
                            })
                        );

                        if (endCallSession.dialog?.sessionState !== 'Terminated') {
                            endCallSession
                                .bye()
                                .then(() => {
                                    setTimeout(() => {
                                        store.dispatch(
                                            callsSlice.actions.callTerminated({
                                                id: endCallSession.id
                                            })
                                        );
                                    }, 1500);
                                })
                                .catch(err => console.warn(err));
                        }
                        break;

                    case SessionState.Terminating:
                    case SessionState.Terminated:
                        (window as any).electron?.send('set-touch-bar', { type: 'clear' });
                        store.dispatch(
                            callsSlice.actions.callTerminating({
                                id: endCallSession.id
                            })
                        );

                        setTimeout(() => {
                            store.dispatch(
                                callsSlice.actions.callTerminated({
                                    id: endCallSession.id
                                })
                            );
                        }, 1500);
                        break;
                }
                break;

            case 'sip/send-dtmf':
                const dtmfSession = calls.find(inv => inv.id === payload.callId);
                if (!dtmfSession) break;

                sendDTMF(dtmfSession, payload.dtmf);

                const { shortcodes } = state.auth;
                const shortCodesDictionary = {};
                shortcodes.forEach(sc => {
                    shortCodesDictionary[sc.short_code] = sc;
                });

                const { popParkedCallsTab } = state.user.settings.phone.settings;

                switch (shortCodesDictionary[payload.dtmf]?.feature?.feature) {
                    case 'parkretrieve':
                        store.dispatch(callsSlice.actions.setCallToParking({ id: payload.callId }));
                        if (popParkedCallsTab) {
                            store.dispatch(appSlice.actions.updateActivePhoneTab('parked'));
                            store.dispatch(appSlice.actions.updateActivePage('phone'));
                        }
                        break;
                }
                break;

            case 'sip/toggle-mute':
                const muteSession = calls.find(inv => inv.id === payload.callId);
                if (!muteSession) break;

                enableSenderTracks(muteSession, payload.muted);

                store.dispatch(
                    callsSlice.actions.updateCall({
                        id: muteSession.id,
                        isMuted: payload.muted
                    })
                );
                break;

            case 'sip/toggle-hold':
                const holdSession = calls.find(inv => inv.id === payload.callId);
                if (!holdSession) break;

                if (!payload.onHold) {
                    calls.forEach(call => {
                        if (call.state === SessionState.Established && call.id !== holdSession.id) {
                            toggleHold(store, call, true);
                        }
                    });
                }

                toggleHold(store, holdSession, payload.onHold);
                break;

            case userSlice.actions.updatePhoneSettings.type:
                switch (action.payload.setting) {
                    case 'dnd':
                        dnd = action.payload.value;
                        break;

                    case 'allowInternalCalls':
                        allowInternalCalls = action.payload.value;
                        break;

                    default:
                        break;
                }
                break;

            case 'sip/disconnect':
                userAgent?.stop().then(() => {
                    userAgent = undefined;
                    lock = false;
                });
                break;

            /** CALL FEEDBACK RELATED */
            case callsSlice.actions.callTerminated.type:
                stopRingtone(payload.id);

                const terminatedCall = action.payload as ICall;
                const secondCall = calls.find(call => call.id !== terminatedCall.id);
                const {
                    sip: { callFeedback }
                } = state;

                if (
                    callFeedback.enabled &&
                    callFeedback.forms.find(form => form.invite_uuid === terminatedCall.id)
                ) {
                    if (callFeedback.forms.length === 1 || !secondCall) {
                        store.dispatch(callsSlice.actions.setShowFeedbackForm(true));
                    }
                }
                break;

            default:
                break;
        }

        return next(action);
    };
